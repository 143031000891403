<template>
  <div :class="{'form-group': !disableFormGroup, 'has-danger': error}">
    <label :for="id" class="textarea__label" :class="{ 'textarea__label--change-checkbox': showChangeCheckbox }" >
      <slot></slot>
      {{ label }}
      <span v-if="required" class="required">*</span>
    </label>
    <slot name="afterLabel"></slot>
    <div class="input-group">
      <Checkbox
        v-if="showChangeCheckbox"
        :id="`change-${id}`"
        :value="changeCheckbox"
        @input="$emit('change-checked', $event)"
        class="input-area__change-checkbox"
      />
      <textarea
        class="form-control"
        :id="id"
        @input="onInput"
        @blur="$emit('blur', $event.target.value)"
        :value="value"
        :rows="rows"
        :maxlength="maxlength"
        :disabled="disabled"
        :data-test="dataAttr ? dataAttr : id"
      >
      </textarea>
      <div class="input-group-append" v-if="showCounter">
        <span class="input-group-text">
          <template v-if="value">{{ value.length }}</template>
          <template v-if="!value">0</template>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import Checkbox from '@/components/form/Checkbox'

export default {
  name: 'Textarea',
  props: {
    id: {
      type: String
    },
    label: {
      type: String
    },
    error: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    disableFormGroup: {
      type: Boolean,
      default: false
    },
    showCounter: {
      type: Boolean,
      default: false
    },
    value: {},
    rows: {
      type: Number,
      default: 3
    },
    maxlength: {
      type: Number,
      default: 5000
    },
    required: {
      type: Boolean,
      default: false
    },
    showChangeCheckbox: {
      type: Boolean,
      default: false
    },
    dataAttr: {
      type: String,
      required: false,
      default: ''
    }
  },
  components: {
    Checkbox
  },
  data: function () {
    return {
      changeCheckbox: false
    }
  },
  methods: {
    onInput (event) {
      if (this.showChangeCheckbox) {
        this.changeCheckbox = true
        this.$emit('change-checked', true)
      } else {
        this.$emit('change-checked')
      }
      this.$emit('input', event.target.value)
    }
  }
}
</script>

<style lang="scss" scoped>
  .textarea {
    &__label {
      @include margin(_ _ 5px);
      display: flex;
      align-items: baseline;
      gap: rem(4px);
      color: #8A96AC;
      &--change-checkbox {
        padding-left: rem(25px);
      }
    }
  }
  .input-group-text {
    width: 50px;
    font-size: 12px;
  }
  .has-danger {
    .input-group-text {
      color: #fc4b6c;
    }
  }
</style>
